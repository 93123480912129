import React, { useState, useEffect, useContext } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { apiGet, apiPut } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
import AuthContext from "../context/AuthContext";
import { startCase } from "lodash";
import obj from "../utils/helpers";

const BetCount = () => {
  const [chetBeat, setChetBeat] = useState(false);
  const [chetBeatObj, setCheatBeatObj] = useState({});
  const [bets, setBets] = useState(false);
  const [eventId, setEventId] = useState("");
  const [matchData, setMatchData] = useState([]);
  const [cheaterData, setCheaterData] = useState([]);
  const [cheaterDataList, setCheaterDataList] = useState([]);
  const [eventDateTime, setEventDateTime] = useState("");
  const { user } = useContext(AuthContext);

  const getMatchData = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.cheatMatchList,
      {
        userId: user?._id,
        userType: user?.userType,
      }
    );
    if (status === 200) {
      if (response_users.success) {
        setMatchData(response_users.results);
      }
    }
  };

  const getCheaterBetData = async (id) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getCheaterBetList,
      { eventId: id }
    );
    if (status === 200) {
      if (response_users.success) {
        setEventId(id);
        setCheaterData(response_users.results);
      }
    }
  };

  const getCheatBetData = async (id) => {
    const { status, data: response_users } = await apiGet(apiPath.getCheatBet, {
      eventId: eventId,
      userId: id,
    });
    if (status === 200) {
      if (response_users.success) {
        setCheaterDataList(response_users.results);
      }
    }
  };
  useEffect(() => {
    getMatchData();
  }, []);

  return (
    <div>
      <section className="main-inner-outer py-4">
        <Container fluid>
          <div className="db-sec">
            <h2 className="common-heading">Cheat Bets</h2>
          </div>

          <div className="inner-wrapper">
            <div className="common-container">
              <div className="account-table batting-table">
                {chetBeat ? (
                  <>
                    <div className="mb-3 text-end">
                      <Button
                        type="button"
                        className="green-btn"
                        onClick={() => {
                          setChetBeat(false);
                          setCheaterData([]);
                        }}
                      >
                        Back
                      </Button>
                    </div>
                    <div className="responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col">SNo.</th>
                            <th scope="col"> User Id </th>
                            <th scope="col">Super Admin</th>
                            <th scope="col">Admin </th>
                            <th scope="col">Sub Admin</th>
                            <th scope="col">Senior Super</th>
                            <th scope="col">Super Agent</th>
                            <th scope="col">Agent</th>
                            <th scope="col">Bet Count</th>
                            <th scope="col">Bunch Count</th>
                            <th scope="col">Show Bets</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cheaterData?.length > 0 &&
                            cheaterData?.map((item, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item?.username}</td>
                                  <td>
                                    {item?.userType === "super_admin"
                                      ? item?.username
                                      : item?.super_admin?.username
                                      ? item?.super_admin?.username
                                      : "-"}
                                  </td>
                                  <td>
                                    {item?.userType === "admin"
                                      ? item?.username
                                      : item?.admin?.username
                                      ? item?.admin?.username
                                      : "-"}
                                  </td>
                                  <td>
                                    {item?.userType === "sub_admin"
                                      ? item?.username
                                      : item?.sub_admin?.username
                                      ? item?.sub_admin?.username
                                      : "-"}
                                  </td>
                                  <td>
                                    {item?.userType === "super_senior"
                                      ? item?.username
                                      : item?.super_senior?.username
                                      ? item?.super_senior?.username
                                      : "-"}
                                  </td>
                                  <td>
                                    {item?.userType === "super_agent"
                                      ? item?.username
                                      : item?.super_agent?.username
                                      ? item?.super_agent?.username
                                      : "-"}
                                  </td>
                                  <td>
                                    {item?.userType === "agent"
                                      ? item?.username
                                      : item?.agent?.username
                                      ? item?.agent?.username
                                      : "-"}
                                  </td>
                                  <td>{item?.betsCount}</td>{" "}
                                  <td>{item?.bunchCount}</td>
                                  <td>
                                    <a
                                      href="#"
                                      className="green-btn"
                                      onClick={() => {
                                        setBets(true);
                                        getCheatBetData(item?._id);
                                      }}
                                    >
                                      Bets
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col">SNo.</th>
                            <th scope="col"> Match Name </th>
                            <th scope="col">Sport</th>
                            <th scope="col">Match Date </th>
                            <th scope="col">User Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchData?.length > 0 &&
                            matchData?.map((res, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td
                                    onClick={() => {
                                      setChetBeat(true);
                                      setEventDateTime(res?.eventDateTime);
                                      getCheaterBetData(res?.eventId);
                                    }}
                                  >
                                    <a href={"#"}>{res?.eventName}</a>
                                  </td>
                                  <td>{startCase(res?.gameType)}</td>
                                  <td>{obj?.dateFormat(res?.eventDateTime)}</td>
                                  <td>{res?.bunchCount}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
              </div>
              {bets && (
                <>
                  <div className="mb-3 ">
                    <Button
                      onClick={() => {
                        setCheaterDataList([]);
                        setBets(false);
                      }}
                      type="button"
                      className="green-btn"
                    >
                      Back
                    </Button>
                  </div>
                  <div className="account-table batting-table">
                    <div className="responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th scope="col">SNo.</th>
                            <th scope="col"> Member </th>
                            <th scope="col">Market</th>
                            <th scope="col"> Selection</th>
                            <th scope="col">Back/Lay</th>
                            <th scope="col">Odds</th>
                            <th scope="col">Stake</th>
                            <th scope="col">Pnl</th>
                            <th scope="col">Date</th>
                            <th scope="col">PlaceTime</th>
                            <th scope="col">MatchedTime</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cheaterDataList?.length > 0 &&
                            cheaterDataList?.map((res, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>nurhosen9999</td>
                                  <td>Match odds</td>
                                  <td>{res?.teamName}</td>
                                  <td>{res?.betType}</td>
                                  <td>{res?.bhav}</td>
                                  <td>{res?.amount}</td>
                                  <td>
                                    {res?.betType == "back"
                                      ? res?.profitAmount
                                      : res?.loseAmount}
                                  </td>
                                  <td>{obj?.dateFormat(res?.timeInserted)}</td>
                                  <td>{obj?.dateFormat(res?.timeInserted)}</td>
                                  <td>{obj?.dateFormat(eventDateTime)}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default BetCount;
