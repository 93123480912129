import React from "react";

export const FancyListDropdown = ({ showMatch, data, selectData }) => {
  // console.log("data",data);
  // console.log("selectData",selectData);
  return (
    showMatch && (
      <tr key={data[0]?.runnerName}>
        <td></td>
        <td></td>
        <td>{data[0]?.runnerName}</td>
        <td className="back-3 p-0" style={{ cursor: "not-allowed" }}>
          <div className="light-blue rounded-0">
            <strong>{selectData && selectData.length>0  &&  selectData[0]?.rt ? selectData[0]?.rt : "--"}</strong>
            <span className="d-block">
              {selectData && selectData.length>0  &&  selectData[0]?.pt ?selectData[0]?.pt : "--"}
            </span>
          </div>
        </td>
        <td></td>
        <td className="lay-2 p-0" style={{ cursor: "not-allowed" }}>
          <div className="dark-pink rounded-0">
            <strong>{selectData && selectData.length>0  &&  selectData[1]?.rt? selectData[1]?.rt : "--"}</strong>
            <span className="d-block">
              {selectData && selectData.length>0  &&  selectData[1]?.pt?selectData[1]?.pt : "--"}
            </span>
          </div>
        </td>

        <td width="80" className="border-l"></td>
      </tr>
    )
  );
};
