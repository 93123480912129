const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  new_user_status: {
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_Status: {
    1: "Open",
    2: "In Active",
    3: "Suspended",
    4: "Closed",
    9: "Ball Start",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  settingHeading: {
    bookmaker: "Bookmaker Listing",
    betFaire: "Bet Fair Listing",
    fancy: "Fancy Listing",
    sportBook: "Premium Fancy Listing",
    completedMatch: "Completed Match",
    preCompletedMatch: "Pre Completed Match",
    tiedMatch: "Tied Match",
    preTiedMatch: "Pre Tied Match",
    toWintheToss: "To Win the Toss",
    preBetFaire: "Pre BetFaire",
    goalUnderOver: "Goal Under Over",
    
  },
  settingArray: [
  
    {
      type: "preTiedMatch",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "tiedMatch",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "preCompletedMatch",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "completedMatch",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    
    {
      type: "preBetFaire",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "betFaire",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "bookmaker",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "fancy",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "sportBook",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
 
    
   
    {
      type: "toWintheToss",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
    {
      type: "goalUnderOver",
      minAmount: "",
      maxAmount: "",
      betDelay: "",
      onShow: 0,
      oddsLimit: "",
      maxProfit: "",
      maxError: false,
    },
  ],
  betCheckData2: [
    {
      value: "Cricket",
      label: "4",
    },
    {
      value: "Tennis",
      label: "2",
    },
    {
      value: "Soccer",
      label: "1",
    },
  ],
  messageType: {
    downline: "Downline",
    all: "All",
    user: "User",
  },
  
  betCheckData: [
    {
      value: "Cricket",
      label: "4",
    },
    {
      value: "Tennis",
      label: "2",
    },
    {
      value: "Soccer",
      label: "1",
    },
    {
      value: "Casino",
      label: "3",
    },
  ],
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  betCheckDataLabel: [
    {
      value: "Cricket",
      label: "cricket",
    },
    {
      value: "Tennis",
      label: "tennis",
    },
    {
      value: "Soccer",
      label: "soccer",
    },
    {
      value: "Casino",
      label: "casino",
    },
  ],
  betCheckDataInner: [
    {
      value: "Bet Fair",
      label: "betfair",
    },
    {
      value: "Bookmaker",
      label: "bookmaker",
    },
    {
      value: "Fancy",
      label: "fancy",
    },
    {
      value: "SportsBook",
      label: "sportBook",
    },
    {
      value: "Tie",
      label: "tie",
    },
    {
      value: "Toss",
      label: "toss",
    }
  ],
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
  homeCasino : [
    
    {
      casino: "kingmaker",
      name:"Baccarat",
      platForm: "KINGMAKER",
      gameType: "TABLE",
      casinoType:"KM-TABLE-026",
      image: "assets/images/home/KM-TABLE-026.png",
    },
    {
      casino: "kingmaker",
      name:"Coin Toss",
      platForm: "KINGMAKER",
      gameType: "TABLE",
      casinoType:"KM-TABLE-036",
      image: "assets/images/home/KM-TABLE-036.png",
    }]
  
   
};

export default obj;
