import React, { useEffect, useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "wc-toast";
import { apiGet, apiPost } from "../utils/apiFetch";
import pathObj from "../utils/apiPath";
import obj from "../utils/constants";
import { useForm } from "react-hook-form";

const ManageLinks = () => {
  const [websiteData, setWebsiteData] = useState("");
  const [linksData, setLinksData] = useState("");

  const [select, setSelect] = useState("");
  const [telegramContent, setTelegramContent] = useState("");
  const [skypeContent, setSkypeContent] = useState("");
  const [emailContent, setEmailContent] = useState("");
  const [facebookContent, setFacebookContent] = useState("");
  const [instagramContent, setInstagramContent] = useState("");
  const [whatsappContent, setWhatsappContent] = useState("");

  const [telegramShowing, setTelegramShowing] = useState("");
  const [skypeShowing, setSkypeShowing] = useState("");
  const [emailShowing, setEmailShowing] = useState("");
  const [facebookShowing, setFacebookShowing] = useState("");
  const [instagramShowing, setInstagramShowing] = useState("");
  const [whatsappShowing, setWhatsappShowing] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({});

  const [search_params, setSearchParams] = useState({
    page: 1,
    limit: 100,
  });
  const getWebsiteData = async () => {
    const { status, data: response_users } = await apiGet(pathObj.listWebsite);
    if (status === 200) {
      if (response_users.success) {
        setWebsiteData(response_users.results?.data);
      }
    }
  };

  const getLinkData = async () => {
    const { status, data: response_users } = await apiGet(
      pathObj.getWebsiteLinkData + "?websiteId=" + select,
      search_params
    );
    if (status === 200) {
      if (response_users.success) {
        setLinksData(response_users.results);
        setTelegramContent(
          response_users.results?.telegramContent
            ? response_users.results?.telegramContent
            : ""
        );
        setWhatsappContent(
          response_users.results?.whatsappContent
            ? response_users.results?.whatsappContent
            : ""
        );
        setEmailContent(
          response_users.results?.emailContent
            ? response_users.results?.emailContent
            : ""
        );
        setFacebookContent(
          response_users.results?.facebookContent
            ? response_users.results?.facebookContent
            : ""
        );
        setInstagramContent(
          response_users.results?.instagramContent
            ? response_users.results?.instagramContent
            : ""
        );
        setSkypeContent(
          response_users.results?.skypeContent
            ? response_users.results?.skypeContent
            : ""
        );

        setTelegramShowing(
          response_users.results?.telegramShowing
            ? response_users.results?.telegramShowing
            : ""
        );
        setWhatsappShowing(
          response_users.results?.whatsappShowing
            ? response_users.results?.whatsappShowing
            : ""
        );
        setEmailShowing(
          response_users.results?.emailShowing
            ? response_users.results?.emailShowing
            : ""
        );
        setInstagramShowing(
          response_users.results?.instagramShowing
            ? response_users.results?.instagramShowing
            : ""
        );
        setFacebookShowing(
          response_users.results?.facebookShowing
            ? response_users.results?.facebookShowing
            : ""
        );
        setSkypeShowing(
          response_users.results?.skypeShowing
            ? response_users.results?.skypeShowing
            : ""
        );
      }
    }
  };

  const onSubmit = async () => {
    console.log("obj", obj);
    try {
      let response = await apiPost(pathObj.updateWebsiteSetting, {
        websiteId: select,
        telegramContent: telegramContent,
        whatsappContent: whatsappContent,
        emailContent: emailContent,
        skypeContent: skypeContent,
        instagramContent: instagramContent,
        facebookContent: facebookContent,
        telegramShowing: telegramShowing,
        whatsappShowing: whatsappShowing,
        emailShowing: emailShowing,
        skypeShowing: skypeShowing,
        instagramShowing: instagramShowing,
        facebookShowing: facebookShowing,
      });
      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (select) {
      getLinkData();
    }
  }, [select]);

  useEffect(() => {
    getWebsiteData();
  }, []);

  return (
    <div>
      <section className="set-limit-sec py-4">
        <Container fluid>
          <Form>
            <Row className="align-items-center">
              <Col md={3}>
                <Form.Group className="d-flex align-items-center mb-3  find-member-sec ">
                  <Form.Label className="pe-2 mb-0 w-75">
                    Select Domain
                  </Form.Label>
                  <Form.Select
                    value={select}
                    onChange={(e) => setSelect(e.target.value)}
                    aria-label="Default select example w-auto"
                  >
                    <option value=""> Select Domain</option>;
                    {websiteData &&
                      websiteData.map((item, index) => {
                        return (
                          <option value={item?._id}>{item?.domain}</option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <h2 className="common-heading">Set Telegram</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Number/Links </Form.Label>
                  <Form.Control
                    type="text"
                    name="telegramContent"
                    placeholder="Enter Number or Links"
                    value={telegramContent}
                    onChange={(e) => setTelegramContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  checked={telegramShowing}
                  className="me-2"
                  type="checkbox"
                  name="telegramShowing"
                  onChange={(e) => setTelegramShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center">
              <h2 className="common-heading">Set Whatsapp</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Number/Links </Form.Label>
                  <Form.Control
                    type="text"
                    name="whatsappContent"
                    placeholder="Enter Number or Links"
                    value={whatsappContent}
                    onChange={(e) => setWhatsappContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  className="me-2"
                  checked={whatsappShowing}
                  type="checkbox"
                  name="whatsappShowing"
                  onChange={(e) => setWhatsappShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center">
              <h2 className="common-heading">Set Email</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Number/Links </Form.Label>
                  <Form.Control
                    type="text"
                    name="emailContent"
                    placeholder="Enter Number or Links"
                    value={emailContent}
                    onChange={(e) => setEmailContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  className="me-2"
                  checked={emailShowing}
                  type="checkbox"
                  name="emailShowing"
                  onChange={(e) => setEmailShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center">
              <h2 className="common-heading">Set Skype</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Number/Links </Form.Label>
                  <Form.Control
                    type="text"
                    name="skypeContent"
                    placeholder="Enter Number or Links"
                    value={skypeContent}
                    onChange={(e) => setSkypeContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  className="me-2"
                  checked={skypeShowing}
                  type="checkbox"
                  name="skypeShowing"
                  onChange={(e) => setSkypeShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center">
              <h2 className="common-heading">Set Instagram</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Number/Links </Form.Label>
                  <Form.Control
                    type="text"
                    name="instagramContent"
                    placeholder="Enter Number or Links"
                    value={instagramContent}
                    onChange={(e) => setInstagramContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  className="me-2"
                  checked={instagramShowing}
                  type="checkbox"
                  name="instagramShowing"
                  onChange={(e) => setInstagramShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row>
            <Row className="align-items-center">
              <h2 className="common-heading">Set Facebook</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Number/Links </Form.Label>
                  <Form.Control
                    type="text"
                    name="facebookContent"
                    placeholder="Enter Number or Links"
                    value={facebookContent}
                    onChange={(e) => setFacebookContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  className="me-2"
                  checked={facebookShowing}
                  type="checkbox"
                  name="facebookShowing"
                  onChange={(e) => setFacebookShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row>
            {/* <Row className="align-items-center">
              <h2 className="common-heading">Set Tv Ads</h2>

              <Col
                xl={6}
                lg={6}
                sm={6}
                className="d-flex align-items-center my-2"
              >
                <Form.Group className="d-flex align-items-center mb-2">
                  <Form.Label className="pe-2 mb-0">Ads Link </Form.Label>
                  <Form.Control
                    type="text"
                    name="adsContent"
                    placeholder="Enter Links"
                    value={adsContent}
                    onChange={(e) => setAdsContent(e.target.value)}
                    className="me-3"
                  />
                </Form.Group>
                <input
                  className="me-2"
                  checked={adsShowing}
                  type="checkbox"
                  name="adsShowing"
                  onChange={(e) => setAdsShowing(e.target.checked)}
                />
                ON (IS SHOWING)
                <Button className="green-btn ms-3" onClick={() => onSubmit()}>
                  Update
                </Button>
              </Col>
            </Row> */}
          </Form>
        </Container>
      </section>
    </div>
  );
};

export default ManageLinks;
